import getEstateDetailRouteName from './getEstateDetailRouteName'
import { useLocaleLink } from '~/composables/useLocaleLink'
import type { EstateType } from '~/types/EstateType'

export default function getEstateDetailLink({
  estateType,
  slug,
  id,
}: {
  estateType: EstateType
  slug: string
  id: number
}) {
  return useLocaleLink({
    name: getEstateDetailRouteName(estateType),
    params: {
      slug,
      id,
    },
  })
}
