import type { EstateType } from '~/types/EstateType'
import type { EstateDetailInterface } from '~/types/api/EstateDetail'
import type { EstateListing } from '~/types/api/EstateListing'

type ParameterMap = {
  label: string
  type: 'custom' | 'text' | 'number' | 'range' | 'boolean' | 'yesNo'
  suffix?: string
  key?: keyof EstateDetailInterface & keyof EstateListing
  maxKey?: keyof EstateDetailInterface & keyof EstateListing
  currency?: string
  image: string
  isEssential?: boolean
  translate?: boolean
  getValue?: (estateData: EstateListing | EstateDetailInterface) => string
}

type Parameters = {
  label: string
  value: string
  image: string
}[]

export default function useParameters(
  estateData: EstateListing | EstateDetailInterface,
  options: {
    variant?: 'default' | 'black'
    essentialsOnly?: boolean
  } = {
    variant: 'default',
    essentialsOnly: false,
  }
) {
  const { t, te } = useI18n()
  const parameters = [] as Parameters

  let iconBasePath = '/icons/parameters'
  if (options.variant === 'black') {
    iconBasePath = '/icons/parameters/black'
  }

  const parametersMap: Record<EstateType, ParameterMap[]> = {
    office: [
      {
        label: t('parameters.labels.totalPropertySize'),
        type: 'number',
        suffix: 'm<sup>2</sup>',
        key: 'total_property_size',
        image: `dimensions.svg`,
        isEssential: false,
      },
      {
        label: t('parameters.labels.minimumUnitSize'),
        type: 'range',
        suffix: 'm<sup>2</sup>',
        key: 'minimum_unit_size',
        maxKey: 'maximum_unit_size',
        image: `dimensions.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.availabilityText'),
        type: 'text',
        key: 'availability_text',
        image: `condition.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.rentFrom', {
          _0: 'm<sup>2</sup>',
        }),
        type: 'range',
        suffix: '€',
        key: 'rent_from',
        maxKey: 'rent_to',
        image: `price.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.spaceDivisions'),
        type: 'yesNo',
        key: 'dividible_premises',
        image: `space-divisions.svg`,
      },
      {
        label: t('parameters.labels.parkingCostFrom'),
        type: 'range',
        suffix: '€',
        key: 'parking_cost_from',
        maxKey: 'parking_cost_to',
        image: `parking.svg`,
        isEssential: false,
      },
    ],
    retail: [
      {
        label: t('parameters.labels.subType'),
        type: 'text',
        key: 'sub_type',
        translate: true,
        image: `shopping-store.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.totalPropertySize'),
        type: 'number',
        suffix: 'm<sup>2</sup>',
        key: 'available_retail_space',
        image: `dimensions.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.unitsCount'),
        type: 'number',
        key: 'number_of_units',
        image: `layout.svg`,
        isEssential: true,
      },
    ],
    warehouse: [
      {
        label: t('parameters.labels.subType'),
        type: 'text',
        key: 'sub_type',
        translate: true,
        image: `shopping-store.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.offerType'),
        type: 'text',
        key: 'offer_type',
        translate: true,
        image: `deal.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.totalPropertySize'),
        type: 'number',
        suffix: 'm<sup>2</sup>',
        key: 'maximum_unit_size',
        image: `dimensions.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.minimumPropertySize'),
        type: 'number',
        suffix: 'm<sup>2</sup>',
        key: 'minimum_unit_size',
        image: `dimensions.svg`,
        isEssential: false,
      },
      {
        label: t('parameters.labels.availabilityText'),
        type: 'text',
        key: 'availability_text',
        image: `condition.svg`,
        isEssential: true,
      },
      {
        label: t('parameters.labels.highwayText'),
        type: 'custom',
        getValue: (estateData) => {
          let value = ''

          if (estateData.highway) {
            value = estateData.highway
          }

          if (value && estateData.highway_exit) {
            value += ` (${estateData.highway_exit})`
          }

          return value
        },
        image: `highway.svg`,
        isEssential: false,
      },
      {
        label: t('parameters.labels.column_grid_a'),
        type: 'text',
        key: 'column_grid_a',
        image: `column_grid_a.svg`,
        suffix: 'm',
        isEssential: false,
      },
      {
        label: t('parameters.labels.clear_height'),
        type: 'text',
        key: 'clear_height',
        image: `clear_height.svg`,
        suffix: 'm',
        isEssential: false,
      },
      {
        label: t('parameters.labels.floor_loading_capacity'),
        type: 'text',
        key: 'floor_loading_capacity',
        image: `floor_loading_capacity.svg`,
        suffix: 't/m<sup>2</sup>',
        isEssential: false,
      },
    ],
  }

  for (const parameter of parametersMap[estateData.type]) {
    if (options.essentialsOnly && parameter.isEssential === false) {
      continue
    }

    if (parameter.type === 'custom') {
      if (
        typeof parameter.getValue !== 'function' ||
        parameter.getValue(estateData) === ''
      ) {
        continue
      }

      parameters.push({
        label: parameter.label,
        value: parameter.getValue(estateData),
        image: `${iconBasePath}/${parameter.image}`,
      })
    }

    if (!parameter.key) {
      continue
    }

    const parameterValue = ref()

    type RawValue = number | string | null | undefined
    const rawValue = estateData[parameter.key] as RawValue
    const rawMaxValue = (
      parameter.maxKey ? estateData[parameter.maxKey] : null
    ) as RawValue

    if (
      typeof rawValue === 'undefined' ||
      rawValue === null ||
      rawValue === 'n/a'
    ) {
      continue
    }

    if (parameter.type === 'text') {
      parameterValue.value = rawValue?.toString()

      // TODO: This would be great, if it would have come translated from the API
      if (parameter.translate) {
        parameterValue.value = te(`parameters.values.${parameterValue.value}`)
          ? t(`parameters.values.${parameterValue.value}`)
          : parameterValue.value
      }
    }

    if (parameter.type === 'number') {
      parameterValue.value = formatNumber(rawValue)
    }

    if (parameter.type === 'range') {
      let value = ''

      if (rawMaxValue) {
        if (rawMaxValue === rawValue) {
          value = formatNumber(rawValue)
        } else {
          value = `${formatNumber(rawValue)} - ${formatNumber(rawMaxValue)}`
        }
      } else {
        value = formatNumber(rawValue)
      }

      parameterValue.value = value
    }

    if (parameter.type === 'yesNo') {
      parameterValue.value =
        rawValue === 'yes'
          ? t('parameters.values.yes')
          : t('parameters.values.no')
    }

    if (parameter.type === 'boolean') {
      parameterValue.value = rawValue
        ? t('parameters.values.yes')
        : t('parameters.values.no')
    }

    if (parameterValue.value) {
      if (parameter.suffix) {
        parameterValue.value += ` ${parameter.suffix}`
      }

      parameters.push({
        label: parameter.label,
        value: parameterValue.value,
        image: `${iconBasePath}/${parameter.image}`,
      })
    }
  }

  return parameters
}
