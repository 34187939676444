<script setup lang="ts">
import type { EstateListing } from '~/types/api/EstateListing'

const props = defineProps<{
  estateData: EstateListing
}>()

const parameters = useParameters(props.estateData, {
  variant: 'black',
  essentialsOnly: true,
})
</script>

<template>
  <div class="c-estate-thumb-parameters">
    <template v-for="parameter in parameters" :key="parameter.label">
      <div class="c-estate-thumb-parameters__item">
        <img class="sc-image" :src="parameter.image" :alt="parameter.label" />

        <div class="sc-content">
          <span v-html="parameter.label" />
          <strong v-html="parameter.value" />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-estate-thumb-parameters {
  --estate-thumb-parameters--image-size: 28px;
  --estate-thumb-parameters--image-display: block;
  --estate-thumb-parameters--label--display: block;
  --estate-thumb-parameters--value--display: block;
  --estate-thumb-parameters--grid-template-columns: repeat(2, auto);

  display: grid;
  grid-template-columns: var(--estate-thumb-parameters--grid-template-columns);
  gap: 14px 18px;

  @include breakpoint-down('md') {
    --estate-thumb-parameters--image-size: 24px;
    --estate-thumb-parameters--label--display: none;

    gap: 12px 16px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 12px;

    .sc-image {
      display: var(--estate-thumb-parameters--image-display);
      width: var(--estate-thumb-parameters--image-size);
      height: var(--estate-thumb-parameters--image-size);
      object-fit: contain;
    }

    .sc-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 16px;
      line-height: 1.2;

      span {
        display: var(--estate-thumb-parameters--label--display);
        font-size: 14px;
        font-weight: 500;
        color: var(--color-solid-grey-dark);
      }

      strong {
        display: var(--estate-thumb-parameters--value--display);
        font-weight: 500;
      }
    }
  }
}
</style>
