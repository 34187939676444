<script setup lang="ts">
import type { EstateContactPerson } from '~/types/api/EstateDetail'

const props = defineProps<{
  contactPerson: EstateContactPerson
}>()

const imageData = getImageSrcSizesData(props.contactPerson.image, {
  defaultFormat: '50x100',
  sizes: '50px',
})
</script>

<template>
  <IPopover
    v-if="props.contactPerson.image"
    events="hover"
    :interactable="true"
    class="c-estate-thumb-contact-person"
    placement="top-end"
  >
    <img
      :src="imageData.src"
      :sizes="imageData.sizes"
      :srcset="imageData.srcset"
      :alt="contactPerson.name"
      loading="lazy"
    />

    <template #body>
      <div class="c-estate-thumb-contact-person__content">
        <div>{{ contactPerson.name }}</div>
        <a v-if="contactPerson.phone" :href="`tel:${contactPerson.phone}`">{{
          contactPerson.phone
        }}</a>
      </div>
    </template>
  </IPopover>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-estate-thumb-contact-person {
  position: absolute;
  right: 20px;
  top: -29px;

  @include breakpoint-down('md') {
    right: 10px;
    top: -24px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
    border: 4px solid var(--color-light);
    box-sizing: content-box;
    display: block;

    @include breakpoint-down('md') {
      width: 40px;
      height: 40px;
    }
  }

  :deep(.popover) {
    white-space: nowrap;

    .popover-trigger {
      cursor: help;
    }
  }
}
</style>
