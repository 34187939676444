<script setup lang="ts">
import Icon from '~/components/global/Icon.vue'
import type { EstateListing } from '~/types/api/EstateListing'

const props = defineProps<{
  estateData: EstateListing
  thumbType?: 'listing-horizontal' | 'listing' | 'slider'
}>()

const sliderId = `estate-thumb-gallery-${props.estateData.id}`

const galleryItems = computed(() => {
  if (!props.estateData.images.length)
    return [
      { src: '/images/no-image.svg', srcset: undefined, sizes: undefined },
    ]

  return props.estateData.images.map((image) => {
    if (props.thumbType === 'slider') {
      return getImageSrcSizesData(image, {
        defaultFormat: '416x832',
        sizes: `
          (max-width: 991.99px) 240px,
          (max-width: 1199.99px) 47.5vw,
          (max-width: 1399.99px) 350px,
          416px
        `,
        srcsetToGenerate: [
          ['416x832', '416w'],
          ['832x1664', '832w'],

          ['350x700', '350w'],
          ['700x1400', '700w'],

          ['240x480', '240w'],
          ['480x960', '480w'],
        ],
      })
    }

    if (props.thumbType === 'listing-horizontal') {
      return getImageSrcSizesData(image, {
        defaultFormat: '240x480',
        sizes: `
          (max-width: 1399.99px) 200px,
          240px
        `,
        srcsetToGenerate: [
          ['240x480', '240w'],
          ['480x960', '480w'],
        ],
      })
    }

    return getImageSrcSizesData(image, {
      defaultFormat: '600x1200',
      sizes: `
        (max-width: 575.99px) 95vw,
        (max-width: 1199.99px) 47.5vw,
        (max-width: 1399.99px) 350px,
        416px
      `,
      srcsetToGenerate: [
        ['416x832', '416w'],
        ['832x1664', '832w'],

        ['350x700', '350w'],
        ['700x1400', '700w'],
      ],
    })
  })
})
</script>

<template>
  <div class="c-estate-thumb-gallery">
    <Swiper
      class="c-estate-thumb-gallery__swiper"
      :slides-per-view="1"
      :modules="[SwiperNavigation]"
      :allow-touch-move="false"
      :navigation="{
        enabled: true,
        prevEl: `.js-navigation-${sliderId} .sc-swiper-button--prev`,
        nextEl: `.js-navigation-${sliderId} .sc-swiper-button--next`,
      }"
    >
      <SwiperSlide v-for="galleryItem in galleryItems" :key="galleryItem.src">
        <img
          :src="galleryItem.src"
          :srcset="galleryItem.srcset"
          :sizes="galleryItem.sizes"
          loading="lazy"
        />
      </SwiperSlide>
    </Swiper>

    <div
      v-if="galleryItems.length > 1"
      :class="['c-estate-thumb-gallery__arrows', `js-navigation-${sliderId}`]"
    >
      <button class="sc-swiper-button sc-swiper-button--prev">
        <Icon name="arrow-left-short" />
      </button>
      <button class="sc-swiper-button sc-swiper-button--next">
        <Icon name="arrow-right-short" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-estate-thumb-gallery {
  --estate-thumb-gallery--arrows--opacity: 0;

  width: 100%;
  height: 100%;
  background-color: var(--color-green-primary-light-2);
  position: relative;

  &__swiper {
    width: 100%;
    height: 100%;
    position: static;

    :deep(.swiper-slide),
    :deep(.swiper-wrapper) {
      position: static;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:not(.swiper-initialized) {
      :deep(.swiper-slide):nth-child(n + 2) {
        display: none;
      }
    }
  }

  &__arrows {
    .sc-swiper-button {
      --swiper-button--size: 32px;
      --swiper-button--offset: 16px;
      --swiper-button--color: var(--color-light);
      --swiper-button--background-color: hsla(
        var(--color-dark-h),
        var(--color-dark-s),
        var(--color-dark-l),
        0.75
      );

      display: grid;
      place-items: center;
      font-size: 12px;
      position: absolute;
      z-index: 10;
      top: calc(50% - var(--swiper-button--size) / 2);
      width: var(--swiper-button--size);
      height: var(--swiper-button--size);
      padding: 0;
      margin: 0;
      border-radius: 50%;
      border: none;
      opacity: var(--estate-thumb-gallery--arrows--opacity);
      color: var(--swiper-button--color);
      background-color: var(--swiper-button--background-color);
      transition: all var(--transition-duration)
        var(--transition-timing-function);

      @include breakpoint-down('lg') {
        display: none;
      }

      &.swiper-button-disabled {
        --swiper-button--background-color: hsla(
          var(--color-dark-h),
          var(--color-dark-s),
          var(--color-dark-l),
          0.25
        );
      }

      &:not(.swiper-button-disabled):hover {
        --swiper-button--color: var(--color-light);
        --swiper-button--background-color: var(--color-dark);
      }

      &--prev {
        left: var(--swiper-button--offset);
      }
      &--next {
        right: var(--swiper-button--offset);
      }
    }
  }
}
</style>
