<script setup lang="ts">
import type { EstateDetailInterface } from '~/types/api/EstateDetail'
import type { EstateListing } from '~/types/api/EstateListing'

const { locale } = useI18n()
const props = defineProps<{
  estateData: EstateDetailInterface | EstateListing
}>()

const values = (() => {
  if (props.estateData.type === 'warehouse') {
    return [
      locale.value === 'sk' ? props.estateData.hub : props.estateData.hub_en,
      props.estateData.town,
    ]
  }

  return [props.estateData.town, props.estateData.street]
})()
</script>

<template>
  <span v-for="(value, index) in values" :key="index">
    {{ value }}<template v-if="index < values.length - 1">, </template>
  </span>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

span {
  display: inline-block;
  margin-right: 4px;
}
</style>
